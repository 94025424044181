



























































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import Sync from './sync.vue'
@Component({ name: 'VulnList', components: { Sync } })
export default class VulnList extends VueBase {
  @Prop() item: any
  @Prop() searchObj: any
  @Prop() settingInte: any
  @Prop() getTableData: any
  openWindow(url: string) {
    window.open(url)
  }

  private relationList:any = []
  private totalPage: any = 1
  private page: number = 1
  private filterObj: any ={
    is_relatived: false,
    vul_type: 'all'
  }
  private filterCurpage:number=1
  private filterList: any = []
  private filterTotal:any = 1
  private relateKey: any = false
  private dialogVisible = false
  private vulTypeData:any = []
  get itemTitle() {
    let title = ''
    title = `${this.item.uri}  ${this.item.http_method} 出现 ${this.item.strategy__vul_name}`
    if (this.item.taint_position) {
      title += '位置:' + this.item.taint_position
    }
    return title
  }

  switchLanguage(language: number) {
    switch (language) {
      case 1:
        return 'JAVA'
      case 2:
        return 'PYTHON'
      case 3:
        return 'PHP'
      case 4:
        return 'G0'
    }
  }
  switchLevel(level: number) {
    switch (level) {
      case 1:
        return '高危'
      case 2:
        return '中危'
      case 3:
        return '低危'
      case 4:
        return '无风险'
      case 5:
        return '提示'
    }
  }

  switchServerType(serverType: string) {
    switch (serverType) {
      case 'tomcat':
        return 'icontomcat'
      case 'jetty':
        return 'iconJetty'
      case 'resin':
        return 'iconresin'
      case 'weblogic':
        return 'iconwebLogic'
      case 'websphere':
        return 'iconwebSphere'
      case 'jboss':
        return 'iconJBoss'

      default:
        return 'iconyingyong'
    }
  }
  private goDetail(item: any) {
    this.$router.push(
      `/vuln/vulnDetail/1/${item.id}?status=` +
        this.searchObj.status +
        '&id=' +
        item.id
    )
  }
  private goRelationDetail(item: any) {
    this.$router.push(
      `/vuln/scanDetail/1/${item.id}?status=` +
        this.searchObj.status +
        '&id=' +
        item.id
    )
  }
  private async getDetails(){
    this.relateKey = !this.relateKey
    if(!this.relateKey){
      return;
    }
    this.getRelationList();
  }
  private async getRelationList() {
    this.loadingStart()
    const param = {
      page_size: 20,
      page: this.page,
      pk: this.item.id
    }
    const res = await this.services.vuln.getRelationList(param);
    this.relateKey = true
    if(res.status == 201){
      this.relationList = res.data;
      this.totalPage = res.page.alltotal
    } else {
      this.$message.error(res.msg)
    }
    this.loadingDone()
  }
  private async getFilterList(){
    this.loadingStart()
    const param:any =  {
      page_size: 20,
      is_relatived: this.filterObj.is_relatived,
      vul_type: this.filterObj.vul_type=='all'?[...this.vulTypeData]:[this.filterObj.vul_type],
      page: this.filterCurpage,
      pk: this.item.id
    }
    const res = await this.services.vuln.getRelationList(param);
    if(res.status == 201){
      this.filterList = res.data;
      this.filterTotal = res.page.alltotal
    } else {
      this.$message.error(res.msg)
    }
    this.loadingDone()
  }
  private async addRelation(param: any){
    let res = await this.services.vuln.getVulType({})
    this.dialogVisible = true
    if(res.status == 201){
      this.vulTypeData = res.data
    }else {
      this.$message.error(res.msg)
    }
    this.getFilterList();
  }
  private changeFilter(){
    this.filterList = [];
    this.filterObj.filterCurpage = 1;
    this.getFilterList();
  }
  private handleCurrentChange(val:any){
    this.page = val
  }
  private filterCurrentChange(val:any){
    this.filterCurpage = val
  }
  private async relationHandler(row: any, done: Function){
    this.loadingStart()
    let res:any= {}
    let requestParam:any={
      iastvul_id: this.item.id,
      dastvul_id: row.id
    }
    if(!!row.is_relatived) {
      res = await this.services.vuln.deleteRelation(requestParam)
    } else {
      res = await this.services.vuln.addRelation(requestParam)
    }
    if(res.status != 201){
      this.$message.error(res.msg)
      return false
    }
    this.loadingDone()
    done();
  }
}
